










import { Component, Prop, Vue } from "vue-property-decorator";
import AfterpayLogo from "../../common/components/afterpay-logo.vue";

@Component({
  name: "maintenance-view",
  components: {
    AfterpayLogo
  }
})
export default class HelloWorld extends Vue {
  hideInitialLoader() {
    document.getElementById("initial-loader")!.style.display = "none";
  }
  mounted() {
    parent.postMessage(
      {
        type: "checkout/resize_frame",
        payload: { height: 250 } //TODO: Needs to adjust the height based on collapsed version as well
      },
      "*"
    );
    this.hideInitialLoader();
  }
}
